"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sources = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _events = require("events");

var _events2 = _interopRequireDefault(_events);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var defaultOptions = {
  withCredentials: false
};

var sources = exports.sources = {};

var EventSource = function () {
  function EventSource(url) {
    var configuration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultOptions;

    _classCallCheck(this, EventSource);

    this.url = url;
    this.withCredentials = configuration.withCredentials;
    this.readyState = 0;
    this.__emitter = new _events2.default();
    sources[url] = this;
  }

  _createClass(EventSource, [{
    key: "addEventListener",
    value: function addEventListener(eventName, listener) {
      this.__emitter.addListener(eventName, listener);
    }
  }, {
    key: "removeEventListener",
    value: function removeEventListener(eventName, listener) {
      this.__emitter.removeListener(eventName, listener);
    }
  }, {
    key: "close",
    value: function close() {
      this.readyState = 2;
    }
  }, {
    key: "emit",
    value: function emit(eventName, messageEvent) {
      this.__emitter.emit(eventName, messageEvent);
    }
  }, {
    key: "emitError",
    value: function emitError(error) {
      if (typeof this.onerror === "function") {
        this.onerror(error);
      }
    }
  }, {
    key: "emitOpen",
    value: function emitOpen() {
      this.readyState = 1;
      if (typeof this.onopen === "function") {
        this.onopen();
      }
    }
  }, {
    key: "emitMessage",
    value: function emitMessage(message) {
      if (typeof this.onmessage === "function") {
        this.onmessage(message);
      }
    }
  }]);

  return EventSource;
}();

exports.default = EventSource;