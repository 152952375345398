'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sources = undefined;

var _EventSource = require('./EventSource');

var _EventSource2 = _interopRequireDefault(_EventSource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _EventSource2.default;
exports.sources = _EventSource.sources;